import PropTypes from "prop-types";
import React from "react";
import Button from "react-bootstrap/Button";
import Card from 'react-bootstrap/Card'
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

const CardFooter = ({ buttonLabel, onClick, isDisabled=false }) => {
    if (!buttonLabel || !onClick) return;

    return (
        <Card.Footer>
            <Row className="justify-content-center">
                <Col md="6" lg="4" className="text-center d-grid">
                    <Button size="lg" onClick={onClick} disabled={isDisabled}>
                        {buttonLabel}
                    </Button>
                </Col>
            </Row>
        </Card.Footer>
    );
}

CardFooter.propTypes = {
    buttonText: PropTypes.string,
    onClick: PropTypes.func,
    isDisabled: PropTypes.bool,
};

export default CardFooter;