import { api } from "./query-interceptors";

/**
 * Retrieve the list of a respondent's non-deleted and non-ignored answers from the API.
 * @param {string} respondentId - The id of the respondent for whom to fetch the answers
 * @param {AbortController} controller - The abort controller to cancel requests
 * @return {Promise} - Returns the axios request promise.
 */
export async function answersGetQuery( respondentId, controller) {
    const requestOptions = {
        method: 'GET',
        url: `/answers/`,
        params: {
            deletedAtIsNull: true,
            ignoredAtIsNull: true,
            limit: 5000,
            respondent: respondentId
        },
        signal: controller.signal
    };
    return api(requestOptions);
}

/**
 * Post a list of answers to the API.
 * @param {(Answer|Demographic)[]} answersToPost - The list of answers to save via the API
 * @param {AbortController} controller - The abort controller to cancel requests
 * @return {Promise} - Returns the axios request promise.
 */
export async function answersPostQuery(answersToPost, controller) {
    const requestOptions = {
        method: 'POST',
        url: `/answers/`,
        data: answersToPost,
        signal: controller.signal
    };
    return api(requestOptions);
}