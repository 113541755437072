import React from "react";
import Card from 'react-bootstrap/Card'
import ReactMarkdown from "react-markdown";

const CardHeader = ({ value }) => {
    if (!value) return;

    return (
        <Card.Header className="text-center h3 pt-3 pb-0">
            <ReactMarkdown>{value}</ReactMarkdown>
        </Card.Header>
    );
}

export default CardHeader;