import { useEffect } from "react";
import { useSetRecoilState } from "recoil";
import { elementIndexAtom } from "_atoms";

/**
* Whenever the field is being skipped, continue incrementing the item index in the same direction
*/
const useFieldContainer = (fieldId, isSkipped) => {
    /** @type Dispatch<SetStateAction<Index>> */
    const setElementIndex = useSetRecoilState(elementIndexAtom);

    useEffect(() => {
        if (!isSkipped || !fieldId) return;

        setElementIndex((prev) => (
            /** @type Index */
            {
                direction: prev.direction,
                value: prev.value + prev.direction
            }
        ));
    }, [fieldId, isSkipped, setElementIndex]);
}

export default useFieldContainer;