import { useRef, useEffect } from "react";
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { assessmentIdAtom } from "_atoms";
import {
    hasFinishedBatchSelector,
    hasReenterableMaterialsSelector,
    hasStartedBatchSelector,
    hasWelcomeSelector,
    materialsSelector,
    numberOfFailedCarelessResponseChecksSelector,
    respondentIdSelector,
    slugOfActiveAssessmentSelector
} from "_selectors";
import { useNavigate } from "react-router-dom";

/**
 * Navigate to the appropriate url when certain application state events occur:
 *      - Failing a careless response check
 *      - Finishing a batch that lacks materials that can be re-entered
 *      - Changing the active assessment
 *      - A welcome message becomes available after initial login for a batch that hasn't been started
 *      - Materials load for the first time and there's only 1 material to administer
 */
const useNavigation = () => {
    /** @type boolean */
    const hasFinishedBatch = useRecoilValue(hasFinishedBatchSelector);
    /** @type boolean */
    const hasReenterableMaterials = useRecoilValue(hasReenterableMaterialsSelector);
    /** @type boolean */
    const hasStartedBatch = useRecoilValue(hasStartedBatchSelector);
    /** @type boolean */
    const hasWelcome = useRecoilValue(hasWelcomeSelector);
    /** @type number */
    const numberOfFailedCarelessResponseChecks = useRecoilValue(numberOfFailedCarelessResponseChecksSelector);
    /** @type string */
    const respondentId = useRecoilValue(respondentIdSelector);
    /** @type {Dispatch<SetStateAction<string>>} */
    const setAssessmentId = useSetRecoilState(assessmentIdAtom);
    /** @type ?string */
    const slugOfActiveMaterial = useRecoilValue(slugOfActiveAssessmentSelector);
    /** @type Material[] */
    const materials = useRecoilValue(materialsSelector);

    const navigate = useNavigate();

    /** The navigate function changes after every url change. That causes the hook to re-execute on each url change,
     * which causes unwanted navigation actions. Using useRef to cache the navigation function avoids these problems. */
    const refNavigate = useRef(navigate);

    useEffect(() => {
        if (!respondentId || !materials?.length) {
            // When the respondent or materials are missing, navigate to the login page
            refNavigate.current('/login');
        } else if (numberOfFailedCarelessResponseChecks > 0) {
            // When there are failed careless response checks, navigate to the quality control page
            refNavigate.current('/quality-control');
        } else if (hasFinishedBatch && !hasReenterableMaterials) {
            // When the batch is recorded as complete and no materials can be re-entered, navigate to the debrief page
            refNavigate.current("/debrief");
        } else if (!!slugOfActiveMaterial) {
            // When there is a slug for the active material, navigate to the material's url
            refNavigate.current(`/assessments/${slugOfActiveMaterial}`);
        }  else if (hasWelcome && !hasStartedBatch) {
            // When there is a welcome message and the batch hasn't yet been recorded as started, navigate to the welcome page
            refNavigate.current("/welcome");
        } else if (materials?.length === 1 && !hasReenterableMaterials) {
            // When there's a single material that can't be re-entered after completion, set the active assessment id and navigate to the assessment
            setAssessmentId(materials[0].assessment);
        } else {
            // When none of the above conditions apply, navigate to the overview page
            refNavigate.current("/overview");
        }
    }, [hasFinishedBatch, hasReenterableMaterials, hasStartedBatch, hasWelcome, materials,
        numberOfFailedCarelessResponseChecks, refNavigate, respondentId, setAssessmentId, slugOfActiveMaterial]);
}

export { useNavigation };