// noinspection SpellCheckingInspection

import React from "react";
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import './LoginCodeOffCanvas.css';
import PropTypes from "prop-types";

const LoginCodeOffCanvas = ({ isVisible, loginCodeValue, onClick }) => {
    // There's a bug in the react-bootstrap code for off canvas components when used in React v17.
    // A removeListener error is thrown when the off canvas component is rendered with show=false.
    // To get around that bug, can just not render the component when isVisible=false.
    if (!isVisible) return null;

    return (
        <Offcanvas backdrop={false} scroll={true} placement="top" show={isVisible} onHide={onClick} className="v-auto login-code-off-canvas">
            <Offcanvas.Header closeButton className="login-code-off-canvas-header">

            </Offcanvas.Header>
            <Offcanvas.Body className="login-code-off-canvas-body">
                <p>
                    To log back in without losing your progress, use the following login code. Please save it in
                    a safe place.
                </p>
                <p className="login-code-value">
                    {loginCodeValue}
                </p>
                <div className="text-center">
                    <Button className="copy-login-code-to-clipboard" variant="outline-primary" onClick={() => {return navigator.clipboard.writeText(loginCodeValue)}}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="20" fill="currentColor" className="bi bi-clipboard" viewBox="0 0 16 20">
                            <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z"/>
                            <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z"/>
                        </svg> Copy to clipboard
                    </Button>
                    <Button className="close-login-code-display ms-3" variant="outline-secondary" onClick={onClick}>
                        I've copied it to a safe place
                    </Button>
                </div>
            </Offcanvas.Body>
        </Offcanvas>
    )
}

LoginCodeOffCanvas.propTypes = {
    isVisible: PropTypes.bool.isRequired,
    loginCodeValue: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired
};


export default LoginCodeOffCanvas;