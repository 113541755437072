import logo from './testerly-logo.svg';
import Container from 'react-bootstrap/Container'
import Image from 'react-bootstrap/Image'
import './Logo.css';

const Logo = () => {
  return (
      <Container className="logo-container">
          <Image className="testerly-logo-image" src={logo} />
          <div className="testerly-logo-text">
              Testerly
          </div>
      </Container>
  );
}

export default Logo;