const productionConstants = {
    apiUrl: 'https://testerly.com/api',
    careerMatchesUrl: 'https://testerly.com/careers/matches',
    sessionCookieDomain: '.testerly.com',
    sessionCookieSecure: 'secure;'
}

const developmentConstants = {
    apiUrl: 'http://127.0.0.1:8000/api',
    careerMatchesUrl: 'http://127.0.0.1:8000/careers/matches',
    sessionCookieDomain: '127.0.0.1',
    sessionCookieSecure: ''
}

/**
 * Constants for use when debugging mobile Safari on a local environment.
 * @type {{apiUrl: string, careerMatchesUrl: string, sessionCookieDomain: string, sessionCookieSecure: string}}
 *
 * const developmentConstantsForMobileSafari = {
 *   apiUrl: 'http://192.168.4.193:8000/api',
 *   careerMatchesUrl: 'http://192.168.4.193:8000/careers/matches',
 *   sessionCookieDomain: '192.168.4.193',
 *   sessionCookieSecure: ''
 * }
 */

export const constants = process.env.NODE_ENV === 'development' ? developmentConstants : productionConstants;