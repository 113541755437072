import { useEffect } from "react";
import { useRecoilState } from 'recoil';
import { progressionsAtom } from "_atoms";
import {
    createRevisedListOfProgressionsAfterSave,
    getUnsavedProgressions,
    setLastProgressionCookie
} from "_helpers/progression-helpers";
import { progressionsPostQuery } from "_queries/progressions-queries";
import useAxiosError from "_actions/axios-error-actions";

/**
 * When the list of progressions changes, post any unsaved progressions to the API.
 */
const useProgressions = () => {
    /** @type [Progression[], Dispatch<SetStateAction<Progression[]>>] */
    const [progressions, setProgressions] = useRecoilState(progressionsAtom);

    /** Actions to handle axios errors */
   const { handleAxiosError } = useAxiosError();

    /**
     * When the list of progressions changes, post any unsaved progressions to the API.
     */
    useEffect(() => {
        /** @type {AbortController} - Use an abort controller to cancel API requests if the component unmounts */
        const controller = new AbortController();
        /** @type Progression[] */
        const unsavedProgressions = getUnsavedProgressions(progressions);

        /**
        * After progressions are posted to the database, update the list of progressions in the app state with
        * isSaved property set to true for all the newly saved ones.
        * @param {AxiosResponse} axiosResponse - The axios response object returned after the API call
        * @return {AxiosResponse} - Returns the same axiosResponse object for chaining purposes
        */
        function saveProgressionsToAppState(axiosResponse) {
            setProgressions((prev) => createRevisedListOfProgressionsAfterSave(prev, axiosResponse.data.results));
            return axiosResponse;
        }

        // When there are unsaved progressions, post them to the API. Record the createdAt property of the most recent progression.
        if (unsavedProgressions.length > 0) {
            progressionsPostQuery(unsavedProgressions, controller).then(saveProgressionsToAppState).catch(handleAxiosError);
            setLastProgressionCookie(progressions);
        }

        // When the effect unmounts, abort any active API requests
        return () => {
            controller.abort();
        };
    }, [handleAxiosError, progressions, setProgressions]);
}

export { useProgressions };