import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import { RecoilRoot } from "recoil";
import AppListeners from "./AppListeners";
import Logo from "_components/Logo/Logo";
import Welcome from 'Welcome/Welcome';
import PageLoadingSpinner from "_components/PageLoadingSpinner/PageLoadingSpinner";
import Login from "Login/Login";
import LoginCodeDisplay from "LoginCodeDisplay/LoginCodeDisplay";
import "./typedefs";
import "bootstrap/dist/css/bootstrap.min.css";

// Lazy load everything after the login page to optimize app performance, loading components only when they are required
const Assessment = React.lazy(() => import('Assessment/Assessment'));
const AssessmentFallback = React.lazy(() => import('_boundaries/AssessmentFallback/AssessmentFallback'));
const Debrief = React.lazy(() => import('Debrief/Debrief'));
const DefaultFallback = React.lazy(() => import('_boundaries/DefaultFallback/DefaultFallback'));
const ListenersFallback = React.lazy(() => import('_boundaries/ListenersFallback/ListenersFallback'));
const Overview = React.lazy(() => import('Overview/Overview'));
const QualityControl = React.lazy(() => import('QualityControl/QualityControl'));

const App = () => {
    return (
        <React.Suspense fallback={<PageLoadingSpinner/>}>
            <RecoilRoot>
                <BrowserRouter>
                    <ErrorBoundary FallbackComponent={DefaultFallback}>
                        <React.Suspense fallback={<PageLoadingSpinner/>}>
                            <Logo/>
                            <LoginCodeDisplay/>
                            <ErrorBoundary FallbackComponent={ListenersFallback}>
                                <AppListeners/>
                            </ErrorBoundary>
                            <Routes>
                                <Route path='/' element={<Login />} />
                                <Route path='login' element={<Login />} />
                                <Route path='login/:login_code_value' element={<Login />} />
                                <Route path='welcome' element={<Welcome />} />
                                <Route path='overview' element={<Overview />} />
                                <Route path='assessments/:slug' element={<ErrorBoundary FallbackComponent={AssessmentFallback}><Assessment /></ErrorBoundary>} />
                                <Route path='quality-control' element={<QualityControl />} />
                                <Route path='debrief' element={<Debrief />} />
                            </Routes>
                        </React.Suspense>
                    </ErrorBoundary>
                </BrowserRouter>
            </RecoilRoot>
        </React.Suspense>
    );
}

export default App;