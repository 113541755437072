import React from "react";
import { useRecoilState, useRecoilValue } from 'recoil';
import Container from 'react-bootstrap/Container';
import { isLoginCodeVisibleAtom, loginCodeAtom } from '_atoms';
import LoginCodeShowButton from "LoginCodeDisplay/components/LoginCodeShowButton/LoginCodeShowButton";
import LoginCodeOffCanvas from "LoginCodeDisplay/components/LoginCodeOffCanvas/LoginCodeOffCanvas";

/**
 * When a respondent logs in using a login code that is not unique to them (e.g., associated with a batch or project),
 * the respondent will automatically be assigned a new login code that is unique to them. That new login code becomes
 * the respondent's username and password for future logins, allowing them to pick up where they left off without losing
 * progress and without needing to divulge any identifying information.
 *
 * The LoginCodeDisplay shows respondents their newly-created login code and provides instructions to save it.
 * The LoginCodeDisplay consists of an off canvas component that shows the login code, and a button component to trigger
 * visibility of the off canvas.
 */
const LoginCodeDisplay = () => {
    /** @type LoginCode */
    const loginCode = useRecoilValue(loginCodeAtom);
    /** @type [boolean, Dispatch<SetStateAction<boolean>>] */
    const [isVisible, setIsVisible] = useRecoilState(isLoginCodeVisibleAtom);

    if (!loginCode?.value || !loginCode?.respondent) return null;

    function showCanvas() {
        setIsVisible(true);
    }

    function hideCanvas() {
        setIsVisible(false)
    }

    return (
        <Container className="position-relative">
            <LoginCodeShowButton
                onClick={showCanvas}
            />
            <LoginCodeOffCanvas
                isVisible={isVisible}
                loginCodeValue={loginCode.value}
                onClick={hideCanvas}
            />
        </Container>
    )
}

export default LoginCodeDisplay;